<template>
    <div>
        <validation-observer
            ref="loginForm"
        >
            <b-form
                class="auth-login-form mt-2"
                @submit.prevent="login(false)"
            >
                <transition-group name="fade" mode="out-in" >
                    <!-- email -->
                    <b-form-group v-if="!twoFAVisible"
                                  :label="i18nT(`Email`)"
                                  label-for="login-email"
                                  :key="`credentials`"
                    >
                        <validation-provider
                            #default="{ errors }"
                            name="Email"
                            vid="email"
                            rules="required|email"
                        >
                            <b-form-input
                                id="login-email"
                                v-model="userEmail"
                                :state="errors.length > 0 ? false : null"
                                name="login-email"
                                :placeholder="i18nT(`workemail@company.com`)"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <!-- forgot password -->
                    <b-form-group v-if="!twoFAVisible" :key="`forgot`">

                        <div class="d-flex justify-content-between">
                            <label for="login-password">{{ i18nT(`Password`) }}</label>
                            <b-link :to="{ name: 'auth-forgot-password', query: {lang: locale} }">
                                <small>{{ i18nT(`Forgot Password?`) }}</small>
                            </b-link>
                        </div>
                        <validation-provider
                            #default="{ errors }"
                            name="Password"
                            vid="password"
                            rules="required"
                        >
                            <b-input-group
                                class="input-group-merge"
                                :class="errors.length > 0 ? 'is-invalid' : null"
                            >
                                <b-form-input
                                    id="login-password"
                                    v-model="password"
                                    :state="errors.length > 0 ? false : null"
                                    class="form-control-merge"
                                    :type="passwordFieldType"
                                    name="login-password"
                                    :placeholder="i18nT(`Password`)"
                                />
                                <b-input-group-append is-text>
                                    <feather-icon
                                        class="cursor-pointer"
                                        :icon="passwordToggleIcon"
                                        @click="togglePasswordVisibility"
                                    />
                                </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group v-if="!twoFAVisible" :key="`checkbox`">
                        <b-form-checkbox
                            id="remember-me"
                            v-model="status"
                            name="checkbox-1"
                        >
                            {{ i18nT(`Remember me`) }}
                        </b-form-checkbox>
                    </b-form-group>

                    <!-- 2FA code -->

                    <b-alert
                        v-if="showFallback && twoFAVisible"
                        class="mt-1"
                        variant="warning"
                        show
                        :key="`fallback`"
                    >
                        <div class="alert-body">
                            <b-badge
                                variant="warning">
                                <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
                                {{ i18nT(`Attention`) }}
                            </b-badge>
                            <p class="font-small-3">{{ i18nT(`It appears that it was not possible to send the two-factor authentication code to your account phone number. The code has been sent to your account e-mail instead.`) }}</p>
                            <p class="font-small-3">
                                {{i18nT(`Didn't get the code?`)}}
                                <span v-if="canResendCode"  @click="login(false, true)" class="text-primary cursor-pointer">{{i18nT(`Click here to resend`)}}</span>
                                <span v-else class="text-secondary">{{i18nT(`You can send it again in`)}} {{ remainingTime }} {{i18nT(`seconds`)}}</span>

                            </p>
                        </div>
                    </b-alert>

                    <b-form-group
                        :key="`2fa`"
                        v-if="twoFAVisible"
                        :label="i18nT(`Enter two-factor authentication code`)"
                    >
                        <b-input-group
                            class="input-group-merge">
                            <b-form-input
                                id="twoFA"
                                v-model="twoFAPassword"
                                name="twoFA"
                                :placeholder="i18nT(`123456`)"
                            />
                            <b-input-group-append v-if="EmailEnabled2fa" is-text>
                                <feather-icon
                                    class="cursor-pointer"
                                    :class="canResendCode ? '' : 'pointer-events-none text-muted'"
                                    icon="MailIcon"
                                    v-b-tooltip.hover.left="i18nT(`Send code via email`)"

                                    @click="login(false, true)"
                                />
                            </b-input-group-append>
                        </b-input-group>
                        <small v-if="!showFallback">{{i18nT(`Code sent via`)}} {{normalizedChannel}}. {{i18nT(`Didn't get the code?`)}}
                            <span v-if="canResendCode" @click="login(true)" class="text-primary cursor-pointer">{{i18nT(`Click to resend`)}}</span>
                            <span v-else class="text-secondary">{{i18nT(`You can send it again in`)}} {{ remainingTime }} {{i18nT(`seconds`)}}</span>
                        </small>


                    </b-form-group>
                </transition-group>

                <!-- checkbox -->




                <!-- submit buttons -->
                <b-button
                    type="submit"
                    variant="primary"
                    block
                    :disabled="loginLoading || (twoFAVisible && !twoFAPassword)"
                >
                    {{ i18nT(`Sign in`) }}
                </b-button>
            </b-form>
        </validation-observer>
    </div>

</template>

<script>
import {
    BAlert,
    BBadge,
    BButton,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BLink,
    VBTooltip,
} from "bootstrap-vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import {required, email} from '@validations'
import axios from "axios";
import {API_KEY, BASE_URL} from "@core/utils/constants";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import useAuth from "@/auth/useAuth";
import $store from "@/store";

export default {
    components: {
        BAlert,
        BBadge,
        BButton,
        BForm,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupAppend,
        BLink,
        ValidationProvider,
        ValidationObserver
    },
    mixins: [togglePasswordVisibility],
    directives: {
        'b-tooltip': VBTooltip
    },
    props: {
        locale: {
            type: String,
            default: 'en'
        }
    },
    data(){
        return {
            loading: false,
            status: '',
            password: '',
            userEmail: '',
            twoFAPassword: '',
            codeChannel: '',
            EmailEnabled2fa: false,
            canResendCode: false,
            twoFAVisible: false,
            showFallback: false,
            loginLoading: false,
            remainingTime: 30,
            timer: null,
            required,
            email,

        }
    },
    created(){},
    computed:{
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        normalizedChannel(){
            if(this.codeChannel == 'whatsapp'){
                return 'WhatsApp';
            }else if(this.codeChannel == 'sms'){
                return 'SMS';
            }else {
                return '';
            }
        }
    },
    methods: {
        startResendTimer() {
            this.remainingTime = 30;
            this.canResendCode = false;
            this.timer = setInterval(() => {
                if (this.remainingTime > 0) {
                    this.remainingTime -= 1;
                } else {
                    this.canResendCode = true;
                    clearInterval(this.timer);
                }
            }, 1000); // Update every second
        },
        login(resending = false, bypass = false) {
            this.$refs.loginForm.validate().then(success => {
                if (success && !this.loginLoading) {

                    let payload = {
                        username: this.userEmail,
                        password: this.password,
                        lang: this.locale,
                    };

                    if(!resending) {
                        payload.pin2fa = this.twoFAPassword;
                    }
                    if(bypass){
                        payload.email2fa = 1;
                        payload.pin2fa = null;
                    }

                    this.loginLoading = true;
                    axios({
                        method: 'post',
                        url: `${BASE_URL}users/publicLogin`,
                        headers: {
                            'Api-Key': API_KEY,
                            'Content-Type': 'application/json;charset=utf-8'
                        },
                        data: payload,
                    })
                        .then(response => {
                            this.loginLoading = false;

                            if(response.data.data.Channel2fa == 'Email') {

                                this.twoFAVisible = true;
                                this.showFallback = true;
                                this.startResendTimer();

                            }
                            else if(response.data.data.token == 'pin2fa'){
                                this.twoFAVisible = true;

                                this.codeChannel = response.data.data.Channel2fa;
                                this.EmailEnabled2fa = response.data.data.EmailEnabled2fa;

                                // Reset the resend timer here
                                clearInterval(this.timer);

                                this.startResendTimer();

                                this.$toast({
                                    component: ToastificationContent,
                                    position: 'top-right',
                                    props: {
                                        title: this.successMessage(response),
                                        icon: 'InfoIcon',
                                        variant: 'success'
                                    }
                                })

                            }
                            else if(response.data.data.token) {

                                useAuth.setToken(response.data.data.token)
                                useAuth.setCurWorkspace(response.data.data.current_workspace)
                                useAuth.setWorkspaces(response.data.data.menu.workspaces)
                                useAuth.setSoftwares(response.data.data.menu.apps)
                                useAuth.setCurrentSoftware(response.data.data.current_sotftware)
                                useAuth.setProfile(response.data.data.menu.user)
                                useAuth.setUserType(response.data.data.user_type)
                                useAuth.setUserPermissions(response.data.data.menu.user.Permissions)
                                // useAuth.setDateFormat(response.data.data.menu.user.Timeformat);

                                localStorage.setItem(
                                    'userData',
                                    JSON.stringify(response.data.data.menu.user)
                                )
                                $store.commit('app/UPDATE_LOCAL_USER', response.data.data.menu.user)
                              this.$http.get('settings/timeformats').then(resp => {
                                const timeformats = resp.data.data;
                                useAuth.setDateFormat(timeformats[response.data.data.menu.user.Timeformat]);
                                useAuth.setDateFormatFlat(response.data.data.menu.user.Timeformat);
                                this.$emit('authenticated', response);

                              });

                                this.$toast({
                                    component: ToastificationContent,
                                    position: 'top-right',
                                    props: {
                                        title: this.i18nT(`Welcome`)+ `, ${response.data.data.menu.user.FirstName} ${response.data.data.menu.user.LastName}`,
                                        icon: 'CoffeeIcon',
                                        variant: 'success',
                                        text: this.i18nT(`You have successfully logged in.`)
                                    }
                                })

                            }

                        })
                        .catch(err => {
                            this.loginLoading = false;

                            clearInterval(this.timer);

                            this.startResendTimer();

                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    // title: err ? err.response.data.message : 'Unable to login',
                                    title: this.errorMessage(err),
                                    icon: 'AlertCircleIcon',
                                    variant: 'danger',
                                    // text: this.i18nT(`Invalid email or password`)
                                }
                            })
                        })
                }
            })
        }
    },
    beforeUnmount() {
        // Clear the timer when the component is destroyed
        clearInterval(this.timer);
    },
    watch: {
        twoFAVisible(newVal) {
            if (!newVal) {
                clearInterval(this.timer); // Clear the timer if 2FA is no longer visible
            }
        },
    }


}

</script>


<style scoped lang="scss">

</style>
