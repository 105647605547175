<template>
    <div class="auth-wrapper auth-v2">
        <b-overlay
            :show="$store.state.app.shallShowOverlay"
            rounded="sm"
            :bg-color="'transparent'"
            :opacity="0.65"
            :fixed="true"
            :no-wrap="true"
            :z-index="10001"
            class="h-100 bg-transparent"
        />
        <b-row class="auth-inner m-0">
            <!-- Brand logo-->
            <b-link class="brand-logo">
                <b-img
                    fluid
                    :src="logoImg"
                    width="120"
                />
            </b-link>
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col
                lg="7"
                class="d-none d-lg-flex align-items-center p-5"
            >
                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                    <b-img
                        fluid
                        :src="imgUrl"
                        alt="Login"
                    />
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Login-->
            <b-col
                lg="5"
                class="d-flex align-items-center auth-bg px-2 p-lg-5"
            >
                <div class="sk-picker">
                    <b-form-select
                        v-model="locale"
                        :options="cultures"
                        label="text"
                    />
                </div>
                <b-col
                    sm="8"
                    md="6"
                    lg="12"
                    class="px-xl-2 mx-auto"
                >

                    <div v-if="loading" class="d-flex align-center align-content-center mb-2">
                        <b-spinner  label="Loading..."
                                    variant="primary"
                                    class="mr-2"
                                    color="primary"></b-spinner>
                        <h4 class="pt-05">
                            {{i18nT(`Activating account...`)}}
                        </h4>
                    </div>

                    <b-card-title
                        class="mb-1 font-weight-bold"
                        title-tag="h2"
                    >
                        {{ i18nT(`Welcome back!`) }} 👋
                    </b-card-title>
                    <b-card-text class="mb-2">
                        {{ i18nT(`Please sign-in to your account.`) }}
                    </b-card-text>

                    <!-- form -->
                    <login-set @authenticated="afterLogin" :locale="locale" />



                    <b-card-text class="text-center mt-2">
                        <span>{{ i18nT(`New on our platform?`) }} </span>
                        <b-link :to="{ name: 'auth-register', query: {lang: locale} }">
                            <span>{{ i18nT(`Create an account`) }}</span>
                        </b-link>
                    </b-card-text>

                    <b-card-text class="text-center mt-2">
                        <b-img
                            fluid
                            :src="gdprImg"
                            class="d-inline mr-50"
                            width="80"
                        />

                        <b-img
                            fluid
                            :src="sslImg"
                            class="d-inline"
                            width="80"
                        />
                    </b-card-text>

                    <!-- divider -->
                    <div class="divider my-2" v-if="false">
                        <div class="divider-text">
                            {{ i18nT(`or`) }}
                        </div>
                    </div>

                    <b-button
                        variant="outline-google"
                        block
                        type="submit"
                        v-if="false"
                    >
                        <b-img
                            fluid
                            :src="googleImg"
                            class="mr-1"
                            width="18"
                        />
                        <!--<span class="icomoon-google"></span>-->
                        {{ i18nT(`Connect with`) }} {{ i18nT(`Google`) }}
                    </b-button>

                    <b-button
                        variant="outline-linkedin"
                        block
                        type="submit"
                        v-if="false"
                    >
                        <b-img
                            fluid
                            :src="linkedinImg"
                            class="mr-1"
                            width="18"
                        />
                        <!--<span class="icomoon-linkedin1"></span>-->
                        {{ i18nT(`Connect with`) }} {{ i18nT(`Linkedin`) }}
                    </b-button>

                </b-col>
            </b-col>
            <!-- /Login-->
        </b-row>
    </div>
</template>

<script>
/* eslint-disable global-require */
import {
    BRow,
    BCol,
    BLink,
    BCardText,
    BCardTitle,
    BImg,
    BButton,
    BFormSelect,
    BSpinner,
    VBTooltip, BOverlay,
} from 'bootstrap-vue'
import useAuth from '@/auth/useAuth'
import {required, email} from '@validations'
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {BASE_URL, API_KEY} from '../../../@core/utils/constants'
import axios from 'axios'
import {isUserLoggedIn} from '@/auth/utils'
import {dictToSelectArray} from "@core/utils/utils";


import loginSet from "@/views/common/Login/LoginSet.vue";

export default {
    directives: {
        'b-tooltip': VBTooltip
    },
    components: {
        BOverlay,
        BRow,
        BCol,
        BLink,
        BCardText,
        BCardTitle,
        BImg,
        BButton,
        BFormSelect,
        BSpinner,
        loginSet
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            loading: false,


            // password: 'lionne79',
            // userEmail: 'cto@dea-advertising.com',
            sideImg: require('@/assets/images/pages/register.svg'),
            logoImg: require('@/assets/images/logo/logo_color.svg'),
            googleImg: require('@/assets/images/icons/google_G_logo.svg'),
            linkedinImg: require('@/assets/images/icons/linkedIn_logo.svg'),
            gdprImg: require('@/assets/images/icons/gdpr.svg'),
            sslImg: require('@/assets/images/icons/ssl.svg'),
            // validation rules
            required,
            email,
            locale: 'en',
            cultures: [],
            loginLoading: false,
            twoFAVisible: false,
            showFallback: false,
        }
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        imgUrl() {
            if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.sideImg = require('@/assets/images/pages/register.svg')
                return this.sideImg
            }
            return this.sideImg
        },
        normalizedChannel(){
            if(this.codeChannel == 'whatsapp'){
                return 'WhatsApp';
            }else if(this.codeChannel == 'sms'){
                return 'SMS';
            }else {
                return '';
            }
        }
    },
    created() {

        let self = this;
        if (isUserLoggedIn()) {
            this.$router.replace('/')
            return;
        }

        this.getCultures();
        if(this.$route.query.lang){
            this.locale = this.$route.query.lang;
        }

        if (this.$route.query.hash) {
            self.loading = true;
            axios({
                method: 'post',
                url: `${BASE_URL}users/publicActivateUser`,
                headers: {
                    'Api-Key': API_KEY,
                    'Content-Type': 'application/json;charset=utf-8'
                },
                data: {
                    hash: this.$route.query.hash,
                    lang: this.locale
                }
            })
                .then(response => {
                    if (response.status !== 'error') {
                        self.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.successMessage(response),
                                icon: 'CoffeeIcon',
                                variant: 'success',
                                text: ``
                            }
                        })
                    } else {
                        self.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.successMessage(response),
                                icon: 'AlertCircleIcon',
                                variant: 'danger',
                                text: ``
                            }
                        })
                    }
                    self.loading = false;
                    setTimeout(() => {
                        self.$router.replace('/login')
                    }, 3000)
                })
                .catch(error => {
                    self.loading = false;
                    self.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.errorMessage(error),
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                            text: ``
                        }
                    })

                    // setTimeout(() => {
                    //   this.$router.replace("/login");
                    // }, 5000);
                })
        }else {

            if(useAuth.getLocale() !== 'undefined' && useAuth.getLocale() !== null){
                this.locale = useAuth.getLocale();
            }else if(this.$route.query.lang) {
                this.locale = this.$route.query.lang;
            }
        }

    },
    methods: {
        afterLogin() {
            let homeRedirectRoute =  getHomeRouteForLoggedInUser();

            this.$router
                .replace(homeRedirectRoute)
                .then(() => {})
                .catch(err => {
                    this.loginLoading = false;
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.errorMessage(err),
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        }
                    })
                })
        },

        getCultures() {
            this.$http.get(`system/cultures`).then(({data}) => {
                console.log("Cultures:", data.data)
                this.cultures = dictToSelectArray(data.data)
                if(this.$route.query.lang) {
                    this.locale = this.$route.query.lang;
                }
            })
        },

    },
    watch: {
        locale: function (val) {
            if(typeof val !== 'undefined' && val !== null){
                this.changeLang(val)
                history.pushState({}, null, `${this.$route.path}?lang=${val}`)
            }
        },
    }

}
</script>

<style scoped>
.sk-picker {
    width: 150px;
    position: absolute;
    top: 20px;
    right: 20px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
